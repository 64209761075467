import React, { useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useForm } from 'react-hook-form'
import {
        FormErrorMessage,
        Box,
        FormControl,
        Text,
        Input,
        InputGroup,
        InputLeftAddon,
        Button,
        Flex,
        useDisclosure,
        FormLabel,
        Textarea,
        Tooltip,
        Checkbox,
        CheckboxGroup,
        Stack,
        Radio,
        RadioGroup,
        VisuallyHidden,
} from '@chakra-ui/react'
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons'

import Recaptcha from 'react-recaptcha'

import Upload from './upload1'
import SubmitModal from './modal'
import ApplicationForm from './applicationform'

import '../styles/test.css'

let recaptchaInstance
const executeCaptcha = e => {
        e.preventDefault()
        recaptchaInstance.execute()
}

export default function HookForm(props) {
        const childRef = useRef()
        const { project } = props

        const { isOpen, onOpen, onClose } = useDisclosure()

        const [Availability, setAvailability] = useState(true)
        const [FirstName, setFirstName] = useState('')
        const [Surname, setSurname] = useState('')
        const [Age, setAge] = useState('')
        const [Location, setLocation] = useState('')
        const [Occupation, setOccupation] = useState('')
        const [Email, setEmail] = useState('')
        const [Phone, setPhone] = useState('')
        const [Sexuality, setSexuality] = useState('not-specified')
        const [GenderIdentity, setGenderIdentity] = useState('not-specified')
        const [CrimRecord, setCrimRecord] = useState('No')
        const [FurtherInfo, setFurtherInfo] = useState('')

        const [FormSuccess, setFormSuccess] = useState(0)
        const [UploadSuccess, setUploadSuccess] = useState(0)
        const [uploadProg, setUploadProg] = useState(0)
        const [selectedFiles, setSelectedFiles] = useState()
        const [filesAttached, setFilesAttached] = useState(0)

        const { handleSubmit, errors, register, formState, control } = useForm()
        //         {
        //         defaultValues: {
        //                 criminal_record: 'NO',
        //         },
        // }

        function validateFirstname(value) {
                let error
                if (!value) {
                        error = 'First Name is required. '
                }
                return error || true
        }

        function validateSurname(value) {
                let error
                if (!value) {
                        error = 'Last Name is required. '
                }
                return error || true
        }

        function validateAvailability(value) {
                let error
                if (!value) {
                        error = 'Please Confirm Your Availability. '
                }
                return error || true
        }

        function validateEmail(inputText) {
                let error
                const re = /^([\w\d._\-#])+@([\w\d._\-#]+[.][\w\d._\-#]+)+$/
                if (!re.test(inputText)) {
                        error = 'Email Address Not Recognized. '
                }
                return error || true
        }

        async function submitHandler() {
                const scriptURL =
                        // 'https://script.google.com/macros/s/AKfycbyV4C_xY9jJuudVA2801zo6xWL6EE8G_TQD3qJdhAFYiUBe84Tm/exec'
                        'https://script.google.com/macros/s/AKfycbzP156xObKMHac1XBYWI3-qzXKcWted0WV0b7T2s7aanOvZlX8/exec'
                const form = document.forms.emailform
                // console.log(document.getElementsByName('occupation')[0].value)
                try {
                        const response = await fetch(scriptURL, { method: 'POST', body: new FormData(form) })
                        console.log('Form Success!', response)
                        setFormSuccess(1)
                } catch (error) {
                        console.error('Form Error!', error.message)
                        setFormSuccess(2)
                }
        }

        async function formPdf() {
                const myElement = ReactDOMServer.renderToString(
                        <ApplicationForm
                                project={project}
                                firstname={FirstName}
                                surname={Surname}
                                age={Age}
                                email={Email}
                                phone={Phone}
                                location={Location}
                                occupation={Occupation}
                                sexuality={Sexuality}
                                genderid={GenderIdentity}
                                criminalrecord={CrimRecord}
                                furtherinfo={FurtherInfo}
                        />
                )
                // console.log(myElement)
                const lambdaPdfFunction = 'https://37ag4pb88c.execute-api.us-east-1.amazonaws.com/dev/html-pdf'
                try {
                        let formPost = ''
                        formPost = await fetch(
                                `${lambdaPdfFunction}?html=${myElement}&name=${project}/${Surname}${FirstName.charAt(
                                        0
                                )}/${Surname}${FirstName.charAt(0)}-application`,
                                { method: 'POST' }
                        )
                        console.log('Form Pdf Success!', formPost)
                } catch (error) {
                        console.error('Form Pdf Error!', error.message)
                }
        }

        async function onSubmit() {
                if (selectedFiles) {
                        // const sleep = function (ms) {
                        //         return new Promise(resolve => setTimeout(resolve, ms))
                        // }
                        await Promise.all([
                                // sleep(1000),
                                childRef.current.upload(),
                                submitHandler(),
                        ])
                        formPdf()
                        onOpen()
                } else {
                        setFilesAttached(1)
                        console.log(filesAttached)
                }
        }

        function onCloseClear() {
                setFirstName('')
                setSurname('')
                setAge('')
                setLocation('')
                setOccupation('')
                setEmail('')
                setPhone('')
                setGenderIdentity('not-specified')
                setSexuality('not-specified')
                setCrimRecord('No')
                setSelectedFiles('')
                setFurtherInfo('')
                onClose()
        }

        return (
                <>
                        <form id="emailform" onSubmit={executeCaptcha} className="myform">
                                <FormControl
                                        isInvalid={
                                                errors.Surname ||
                                                errors.Firstname ||
                                                errors.Email ||
                                                errors.Phone ||
                                                errors.Availability ||
                                                errors.Age
                                        }
                                        className="formcontrolx"
                                >
                                        <InputGroup
                                                mt={3}
                                                mb={3}
                                                border="1px solid"
                                                borderColor="purple.100"
                                                borderRadius="0.375rem"
                                                padding={3}
                                        >
                                                <Checkbox
                                                        name="ConfirmedAvailability"
                                                        isRequired
                                                        ref={register({ validate: validateAvailability })}
                                                        value={Availability}
                                                        onClick={e => setAvailability(e.target.checked)}
                                                        width="100%"
                                                        borderColor="purple.100"
                                                        margin={3}
                                                        defaultIsChecked
                                                >
                                                        <Text fontSize="l" fontWeight="bold" ml={2}>
                                                                I CONFIRM I AM AVAILABLE FROM 04 Feb to 09 Feb 2020 TBC{' '}
                                                        </Text>
                                                </Checkbox>
                                        </InputGroup>
                                        <Input
                                                name="Firstname"
                                                placeholder="First Name"
                                                isRequired
                                                ref={register({ validate: validateFirstname })}
                                                onChange={e => setFirstName(e.target.value)}
                                                value={FirstName}
                                                m="2"
                                                width={{ sm: '100%', xl: '30%' }}
                                                flexGrow="1"
                                                borderColor="purple.100"
                                        />
                                        <Input
                                                name="Surname"
                                                placeholder="Last Name"
                                                isRequired
                                                ref={register({ validate: validateSurname })}
                                                onChange={e => setSurname(e.target.value)}
                                                value={Surname}
                                                m="2"
                                                width={{ sm: '100%', xl: '30%' }}
                                                flexGrow="1"
                                                borderColor="purple.100"
                                        />
                                        <Box mr={2} width="100%">
                                                <FormLabel htmlFor="Age" ml={2} mt={4} mb={0}>
                                                        Age
                                                </FormLabel>

                                                <InputGroup
                                                        ml={2}
                                                        mb={4}
                                                        border="1px solid"
                                                        borderColor="purple.100"
                                                        borderRadius="0.375rem"
                                                >
                                                        <RadioGroup onChange={setAge} value={Age} name="Age">
                                                                <Flex
                                                                        margin={2}
                                                                        flexWrap="wrap"
                                                                        flexDirection="row"
                                                                        height={{ sm: '150px', md: '100px' }}
                                                                >
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="17-20"
                                                                        >
                                                                                17 - 20
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="20-25"
                                                                        >
                                                                                20 - 25
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="25-30"
                                                                        >
                                                                                25 - 30
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="30-40"
                                                                        >
                                                                                30 - 40{' '}
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="40-50"
                                                                        >
                                                                                40 - 50
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="50-60"
                                                                        >
                                                                                50 - 60
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="60-70"
                                                                        >
                                                                                60 - 70
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        xl: '25%',
                                                                                }}
                                                                                value="70-80"
                                                                        >
                                                                                70 - 80
                                                                        </Radio>
                                                                        <Radio
                                                                                flexBasis={{
                                                                                        base: '50%',
                                                                                        md: '32%',
                                                                                        lg: '25%',
                                                                                }}
                                                                                value="80+"
                                                                        >
                                                                                80+
                                                                        </Radio>
                                                                </Flex>
                                                        </RadioGroup>
                                                </InputGroup>
                                        </Box>
                                        <InputGroup mt={3}>
                                                <InputLeftAddon
                                                        ml={2}
                                                        pointerEvents="none"
                                                        borderColor="purple.100"
                                                        children=<EmailIcon color="gray.800" />
                                                />
                                                <Input
                                                        name="Email"
                                                        placeholder="Email"
                                                        isRequired
                                                        ref={register({ validate: validateEmail })}
                                                        onChange={e => setEmail(e.target.value)}
                                                        value={Email.trim()}
                                                        width={{ sm: '100%', xl: '50%' }}
                                                        flexGrow="1"
                                                        borderColor="purple.100"
                                                />
                                        </InputGroup>
                                        <InputGroup mt={3}>
                                                <InputLeftAddon
                                                        ml={2}
                                                        pointerEvents="none"
                                                        borderColor="purple.100"
                                                        children=<PhoneIcon color="gray.800" />
                                                />
                                                <Input
                                                        name="Phone"
                                                        placeholder="Phone"
                                                        type="tel"
                                                        pattern="^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$"
                                                        ref={register()}
                                                        onChange={e => setPhone(e.target.value)}
                                                        value={Phone}
                                                        width={{ sm: '100%', xl: '30%' }}
                                                        flexGrow="1"
                                                        borderColor="purple.100"
                                                />
                                        </InputGroup>
                                        <InputGroup mt={3}>
                                                <InputLeftAddon ml={2} pointerEvents="none" borderColor="purple.100">
                                                        Location
                                                </InputLeftAddon>
                                                <Input
                                                        name="Location"
                                                        placeholder="Town/City"
                                                        ref={register()}
                                                        onChange={e => setLocation(e.target.value)}
                                                        value={Location}
                                                        width={{ sm: '100%', xl: '30%' }}
                                                        flexGrow="1"
                                                        borderColor="purple.100"
                                                />
                                        </InputGroup>
                                        <InputGroup mt={3}>
                                                <InputLeftAddon ml={2} pointerEvents="none" borderColor="purple.100">
                                                        Occupation
                                                </InputLeftAddon>
                                                <Input
                                                        name="occupation"
                                                        placeholder="Occupation"
                                                        ref={register()}
                                                        onChange={e => setOccupation(e.target.value)}
                                                        value={Occupation}
                                                        width={{ sm: '100%', xl: '30%' }}
                                                        flexGrow="1"
                                                        borderColor="purple.100"
                                                />
                                        </InputGroup>
                                        <Box width={{ sm: '100%', l: '50%' }} flexGrow="1" mr={2}>
                                                <FormLabel htmlFor="Sexuality" ml={2} mt={4} mb={0}>
                                                        Sexuality
                                                </FormLabel>

                                                <InputGroup
                                                        mt={3}
                                                        border="1px solid"
                                                        borderColor="purple.100"
                                                        borderRadius="0.375rem"
                                                >
                                                        <RadioGroup
                                                                onChange={setSexuality}
                                                                value={Sexuality}
                                                                name="Sexuality"
                                                        >
                                                                <Stack margin={2} direction="column">
                                                                        <Radio value="Asexual">Asexual</Radio>
                                                                        <Radio value="Bisexual">Bisexual</Radio>
                                                                        <Radio value="Gay">Gay</Radio>
                                                                        <Radio value="Heterosexual">
                                                                                Heterosexual/Straight
                                                                        </Radio>
                                                                        <Radio value="Lesbian">Lesbian/Gay Woman</Radio>
                                                                        <Radio value="Pansexual">Pansexual</Radio>
                                                                        <Radio value="Queer">Queer</Radio>
                                                                        <Radio value="Questioning">
                                                                                Questioning or Unsure
                                                                        </Radio>
                                                                        <Radio value="Same-gender-loving">
                                                                                Same-gender Loving
                                                                        </Radio>
                                                                        <Radio value="prefer-not-to-disclose">
                                                                                Prefer Not To Disclose
                                                                        </Radio>
                                                                        <VisuallyHidden>
                                                                                <Radio
                                                                                        isChecked
                                                                                        value="not-specified"
                                                                                />
                                                                        </VisuallyHidden>
                                                                </Stack>
                                                        </RadioGroup>
                                                </InputGroup>
                                        </Box>
                                        <Box width={{ sm: '100%', l: '50%' }} flexGrow="1">
                                                <FormLabel htmlFor="Gender Identity" ml={2} mt={4} mb={0}>
                                                        Gender Identity
                                                </FormLabel>

                                                <InputGroup
                                                        mt={3}
                                                        border="1px solid"
                                                        borderColor="purple.100"
                                                        borderRadius="0.375rem"
                                                >
                                                        <RadioGroup
                                                                onChange={setGenderIdentity}
                                                                value={GenderIdentity}
                                                                defaultValue={GenderIdentity}
                                                                name="GenderIdentity"
                                                        >
                                                                <Stack margin={2} direction="column">
                                                                        <Radio value="Agender">Agender</Radio>
                                                                        <Radio value="Androgyne">Androgyne</Radio>
                                                                        <Radio value="Demigender">Demigender</Radio>
                                                                        <Radio value="Genderqueer">
                                                                                Genderqueer or Gender Fluid
                                                                        </Radio>
                                                                        <Radio value="Man">Man</Radio>
                                                                        <Radio value="Questioning">
                                                                                Questioning or Unsure
                                                                        </Radio>
                                                                        <Radio value="Trans-man">Trans Man</Radio>
                                                                        <Radio value="Trans-woman">Trans Woman</Radio>
                                                                        <Radio value="Woman">Woman</Radio>
                                                                        <Radio value="Prefer-not-to-disclose">
                                                                                Prefer Not To Disclose
                                                                        </Radio>
                                                                        <VisuallyHidden>
                                                                                <Radio
                                                                                        isChecked
                                                                                        value="not-specified"
                                                                                />
                                                                        </VisuallyHidden>
                                                                        agender
                                                                </Stack>
                                                        </RadioGroup>
                                                </InputGroup>
                                        </Box>

                                        <InputGroup
                                                mt={3}
                                                mb={3}
                                                border="1px solid"
                                                borderColor="purple.100"
                                                borderRadius="0.375rem"
                                                padding={3}
                                        >
                                                <RadioGroup
                                                        onChange={setCrimRecord}
                                                        value={CrimRecord}
                                                        defaultValue={CrimRecord}
                                                        name="criminal_record"
                                                >
                                                        <Stack margin={3} direction={{ sm: 'column', lg: 'row' }}>
                                                                <Radio value="Yes">I Have a Criminal Record</Radio>
                                                                <Radio isChecked value="No">
                                                                        I Have NO Criminal Record
                                                                </Radio>
                                                        </Stack>
                                                </RadioGroup>
                                                {/* <CheckboxGroup>
                                                        <Checkbox
                                                                value={CrimRecord}
                                                                name="criminal_record"
                                                                isChecked={CrimRecord}
                                                                ref={register}
                                                                onClick={e =>
                                                                        e.target.checked
                                                                                ? setCriminalRecord('YES')
                                                                                : setCriminalRecord('NO')
                                                                }
                                                                // onClick={e => setCrimRecord(e.target.checked)}
                                                                width="100%"
                                                                borderColor="purple.100"
                                                                margin={3}
                                                        > */}
                                                {/* <Text ml={2}>I have a criminal record</Text>
                                                        </Checkbox>
                                                </CheckboxGroup> */}
                                        </InputGroup>
                                        <FormLabel htmlFor="OtherInfo" ml={2} mt={4} mb={0} width="100%">
                                                Further Information
                                        </FormLabel>
                                        <Textarea
                                                name="OtherInfo"
                                                placeholder="Any further information"
                                                m="2"
                                                ref={register()}
                                                borderColor="purple.100"
                                                onChange={e => setFurtherInfo(e.target.value)}
                                                value={FurtherInfo.replace(/[\r\n]+/gm, '')}
                                        />
                                        <FormErrorMessage>
                                                <Flex direction="column">
                                                        <Box width="100%">
                                                                {errors.Firstname && errors.Firstname.message}
                                                        </Box>
                                                        <Box width="100%">
                                                                {errors.Surname && errors.Surname.message}
                                                        </Box>
                                                        <Box width="100%">{errors.Email && errors.Email.message}</Box>
                                                        <Box>{errors.Availability && errors.Availability.message}</Box>
                                                </Flex>
                                        </FormErrorMessage>
                                </FormControl>
                        </form>

                        <Flex flexDirection="column" justifyContent="left" className="bottom-block">
                                <Upload
                                        ref={childRef}
                                        surname={Surname}
                                        firstname={FirstName}
                                        uploadprog={uploadProg}
                                        setuploadprog={setUploadProg}
                                        uploadsuccess={UploadSuccess}
                                        setuploadsuccess={setUploadSuccess}
                                        setselectedfiles={setSelectedFiles}
                                        selectedfiles={selectedFiles}
                                        setfilesattached={setFilesAttached}
                                        project={project}
                                />
                                {filesAttached ? (
                                        <div aria-live="polite" style={{ color: 'red' }}>
                                                Please Attach your Self Tape
                                        </div>
                                ) : (
                                        ''
                                )}

                                <Button
                                        width="200px"
                                        onClick={handleSubmit(onSubmit)}
                                        mt={4}
                                        isLoading={formState.isSubmitting}
                                        borderColor="#811672"
                                        borderWidth="1px"
                                >
                                        {' '}
                                        Submit{' '}
                                </Button>
                        </Flex>
                        <SubmitModal
                                isopen={isOpen}
                                onopen={onOpen}
                                onclose={onCloseClear}
                                firstname={FirstName}
                                surname={Surname}
                                uploadsuccess={UploadSuccess}
                                formsuccess={FormSuccess}
                        />

                        <Recaptcha
                                ref={e => (recaptchaInstance = e)}
                                sitekey="6LdEiOEZAAAAALkNjecKwayqjmPyqcrgMW-mfNvk"
                                boxSize="invisible"
                                verifyCallback={handleSubmit(onSubmit)}
                        />
                </>
        )
}
